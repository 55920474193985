/** @format */

section#section9 {
  background-image: url(./icons/noise.png), linear-gradient(#fff, #fff);
  background-position: 0 0, 0 0;
  background-size: auto, auto;
  width: 100%;
}

.main-div.sct9 {
  padding-top: 60px;

  h2 {
    margin: 0 auto;
  }
}

.sct9-main-div {
  max-width: 1240px;
  margin: 0 auto;
  padding: 50px;
  margin-top: 100px;
  border: 6px solid #fbe8de;
  position: relative;
}

.sct9-div {
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  font-family: "jj";
  font-weight: 700;
}

.sct9-main-item {
  display: flex;
  flex-direction: column;
}

.sct9-item {
  border-bottom: 6px solid #fbe8de;
  margin-bottom: 30px;
  padding-bottom: 16px;
  font-size: 17px;
  line-height: 20px;
}

.sct9svg1 {
  width: 100px;
  position: absolute;
  top: -7%;
  left: -5%;
  bottom: auto;
}

@media (max-width: 767px) {
  .sct9-div {
    grid-template-columns: 1fr;
  }

  .sct9svg1 {
    top: -4%;
  }
}

@media (max-width: 575px) {
  .sct9-main-div {
    padding: 20px;
  }

  .sct9svg1 {
    top: -4%;
    bottom: auto;
    left: auto;
    right: -5%;
  }
}
