/** @format */

section#section8 {
  background-image: url(./icons/noise.png), linear-gradient(#fff, #fff);
  background-position: 0 0, 0 0;
  background-size: auto, auto;
  width: 100%;
  position: relative;
}

.sct8 {
  padding-top: 100px;
}

.sct8-div {
  background-image: url(./icons/noise.png), linear-gradient(#fbe8de, #fbe8de);
  background-position: 0 0, 0 0;
  background-size: auto, auto;
  padding: 80px;
  max-width: 1240px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;

  h3 {
    font-size: 46px;
    line-height: 46px;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 40px;
  }
}

.sct8svg1 {
  position: absolute;
  left: 0;
  right: 0;
  top: -53px;
  margin: 0 auto;
}

.sct8svg2 {
  top: 0;
  right: 0;
  max-width: 24vw;
  position: absolute;
}

@media (max-width: 991px) {
  .sct8svg2 {
    max-width: 40vw;
  }

  .sct8-div div {
    max-width: 390px;
  }
}

@media (max-width: 767px) {
  .sct8-div {
    padding-top: 120px;
  }

  .sct8-div div {
    max-width: 330px;
  }
}

@media (max-width: 575px) {
  .sct8-div {
    padding-top: 145px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
