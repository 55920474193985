/** @format */

section#section2 {
  background-image: url(./icons/noise.png), linear-gradient(#fbe8de, #fbe8de);
  background-position: 0 0, 0 0;
  background-size: auto, auto;
  padding-top: 200px;
}

.hero-ab1.r1 {
  display: none;
}

.hero-ab1.r2 {
  display: block;
}

.main-button {
  letter-spacing: 0.5px;
  background-color: #000;
  border-radius: 8px;
  font-size: 17px;
  font-weight: 700;
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 24px 60px;
  width: fit-content;
  box-shadow: 6px 6px rgba(0, 0, 0, 0.2);
  font-family: "jj";
  font-feature-settings: "case";

  &:hover {
    transform: translateY(-6px);
    box-shadow: 8px 8px rgba(0, 0, 0, 0.2);
  }
}

section#section2 {
  h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 80px;
    line-height: 90px;
    text-align: center;
    z-index: 1;

    span {
      display: none;
    }
  }

  .sc2-txt1 {
    margin-top: 20px;
    font-size: 24px;
    text-align: center;
    font-family: "jj";
    line-height: 36px;
  }

  a {
    margin-top: 40px;
    z-index: 4;
  }

  .sc2-txt2 {
    margin-top: 30px;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-family: "jj";

    span {
      height: 18px;
      margin-left: 4px;
      margin-right: 2px;
    }
  }
}

.main-sc2 {
  margin: 0 auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5%;
  margin-bottom: 100px;
}

.hero-ab1 {
  width: 12vw;
  height: auto;
  max-width: 250px;
  position: absolute;
  top: 15%;
  left: 0;
}

.hero-ab2 {
  width: 16vw;
  height: auto;
  max-width: 250px;
  position: absolute;
  top: 15%;
  right: 0;
}

.sc2-txt01 {
  display: none;
}

@media (max-width: 991px) {
  section#section2 h1 {
    line-height: 80px;
    font-size: 80px;
  }

  .hero-ab1 {
    width: 25vw;
    max-width: 25vw;
    top: 15%;
    bottom: auto;
    left: 0%;
  }

  .hero-ab2 {
    width: 30vw;
    max-width: 24vw;
    top: auto;
    bottom: 37%;
  }

  section#section2 {
    padding-top: 280px;
  }
}

@media (max-width: 767px) {
  section#section2 a {
    z-index: 3;
  }

  .hero-ab1 {
    width: 20vw;
    max-width: 20vw;
    top: 5%;
    left: -5%;
  }

  .hero-ab2 {
    bottom: 28%;
    right: 0;
  }

  section#section2 h1 {
    line-height: 60px;
    font-size: 56px;
    br {
      display: none;
    }

    span {
      display: block;
    }
  }
}

@media (max-width: 624px) {
  .hero-ab1.r1 {
    display: block;
  }

  .hero-ab1.r2 {
    display: none;
  }

  .hero-ab2 {
    display: none;
  }

  .ab2none {
    fill: none;
  }

  .sc2-txt01 {
    font-size: 58px;
    line-height: 58px;
    display: block;
    font-weight: 700;
    text-align: center;
  }

  section#section2 h1 {
    font-size: 54px;
    line-height: 55px;
    display: none;
  }

  .main-sc2 {
    padding: 0 20px;
  }

  section#section2 a {
    margin-top: 30px;
  }

  section#section2 {
    padding-top: 180px;
  }
}
