/** @format */

section.privacy {
  background-image: url(./icons/noise.png), linear-gradient(#fff, #fff);
  background-position: 0 0, 0 0;
  background-size: auto, auto;
  width: 100%;

  div {
    a {
      button {
        text-decoration: underline;
        text-decoration-color: black;
        border: 1px solid transparent;
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.privacy-policy {
  max-width: 1200px;
  margin: 0 auto;
  font-family: "gr";
}

.privacy-polict-table {
  width: 100%;
  overflow-x: scroll;
}

section#vaja {
  background-image: url(./icons/noise.png), linear-gradient(#fbe8de, #fbe8de);
  background-position: 0 0, 0 0;
  background-size: auto, auto;
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

@media (max-width: 575px) {
  section.privacy div a button {
    padding-left: 40px;
    padding-right: 40px;
  }
}
