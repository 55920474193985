/** @format */

@font-face {
  font-family: "gr";
  src: url("../fonts/TTL.ttf") format("truetype");
}

@font-face {
  font-family: "jj";
  src: url("../fonts/SFG.ttf") format("truetype");
}
