/** @format */

section#section4 {
  background-image: url(./icons/noise.png), linear-gradient(#fff, #fff);
  background-position: 0 0, 0 0;
  background-size: auto, auto;
  width: 100%;
}

.sct4 {
  padding-top: 100px;
  padding-bottom: 160px;
}

.sct4-div {
  display: flex;
  justify-content: center;
  column-gap: 60px;
  row-gap: 30px;
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 80px;
  font-family: "jj";

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 320px;

    p {
      margin-top: 10px;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
    }
  }
}

.bnr-logo {
  height: 50px;
  width: 92px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.sct4-svg {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sct4-banner {
  position: relative;
  margin: 0 auto;
  background-color: #fbe8de;
  background-image: url(./icons/noise.png);
  background-position: 0 0;
  background-size: auto;
  margin-top: 80px;
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  max-width: 1240px;
  font-weight: 700;

  p {
    font-size: 33px;
    text-align: center;
    line-height: 40px;
  }
}

@media (max-width: 767px) {
  .sct4-div {
    flex-direction: column;
    align-items: center;
    row-gap: 40px;
  }
}

@keyframes notify {
  0%,
  to {
    transform: rotate(0deg);
    transform-origin: top center;
  }
  10%,
  90% {
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    transform: rotate(-6deg);
  }
  30%,
  50%,
  70% {
    transform: rotate(6deg);
  }
  80% {
    transform: rotate(-2deg);
  }
}

.noti-svg {
  height: 80px;
  width: 80px;
  g {
    animation: notify 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both infinite;
  }
}

.clock-svg {
  height: 80px;
  width: 80px;
}

.rotate {
  animation: rotate 2s linear infinite both;
  transform-origin: center;
}

.set-svg {
  width: 80px;
  height: 80px;

  g {
    animation: rotate 3s cubic-bezier(0.7, -0.03, 0.26, 1.05) both infinite;
    transform-origin: center center;
  }
}

.bnr1 {
  position: absolute;
  bottom: 10%;
  left: -5%;
}

.bnr2 {
  position: absolute;
  top: -4%;
  right: -4%;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 991px) {
  .bnr1 {
    width: 130px;
    max-width: none;
  }
  .bnr2 {
    width: 150px;
    max-width: none;
  }
}

@media (max-width: 575px) {
  .sct4-banner {
    letter-spacing: 0.5px;
    padding: 40px 30px;
    font-size: 30px;
    line-height: 34px;

    p {
      max-width: 302px;
    }
  }

  .sct4 {
    padding-bottom: 140px;
  }
}

@media (max-width: 479px) {
  .bnr1 {
    width: 60px;
    bottom: 16%;
  }
  .bnr2 {
    width: 120px;
  }
}
