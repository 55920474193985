/** @format */

footer#section12 {
  background-image: url(./icons/noise.png), linear-gradient(#000, #000);
  background-position: 0 0, 0 0;
  background-size: auto, auto;
  width: 100%;
  color: white;
  position: relative;
}

.main-div.sct12 {
  padding-bottom: 120px;
}

footer .main-button {
  border: 1px solid #fff;
  border-radius: 20px;
  height: 142px;
  font-size: 28px;
  transition: all 0.6s;
  align-items: center;
  margin-top: 60px;
  width: 100%;
  max-width: 800px;

  &:hover {
    background-color: #fff;
    color: #000;
    transform: translateY(-13px);
    box-shadow: 9px 9px rgba(255, 255, 255, 0.24);
  }
}

.footer-logos {
  max-width: 1240px;
  margin: 0 auto;
  margin-top: 60px;
  display: flex;
  justify-content: space-evenly;
}

.footer-bottom {
  margin: 0 auto;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  max-width: 1240px;
}

.footer-links {
  display: flex;
  column-gap: 60px;
  font-family: "jj";

  div {
    row-gap: 16px;
    display: flex;
    flex-direction: column;

    a {
      color: white;
      text-decoration: none;
      transition: 0.3s;
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      &:hover {
        color: #ea552b;
      }
    }
  }
}

.sct12svg {
  position: absolute;
  left: 0;
  bottom: 0;
}

.vaja-link {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: black;
  transition: 0.5s;
  opacity: 0.1;

  &:hover {
    background-color: #fbe8de;
  }

  &:active {
    opacity: 1;
  }
}

.vajahref {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #fbe8de;
  transition: 0.5s;
  opacity: 0.1;
  color: transparent;

  &:hover {
    background-color: #000;
  }

  &:active {
    opacity: 1;
  }
}

@media (max-width: 991px) {
  .footer-logos {
    // padding-bottom: 120px;
    flex-wrap: wrap;
    column-gap: 20px;
  }

  .sct12 {
    padding-top: 120px;
  }

  .sct12svg {
    width: 100px;
  }
}

@media (max-width: 575px) {
  .sct12 {
    padding-top: 60px;

    h2 {
      font-size: 42px;
      line-height: 44px;
    }

    .h2subtitle {
      margin-top: 20px;
    }
  }

  footer .main-button {
    margin-top: 40px;
    line-height: 30px;
  }

  .footer-bottom {
    flex-direction: column;
    row-gap: 40px;
  }
}

@media (max-width: 380px) {
  .sct12 h2 {
    font-size: 28px;
    line-height: 33px;
  }

  .sct12 .h2subtitle {
    font-size: 17px;
    line-height: 26px;
  }
}
