/** @format */

section#section3 {
  position: relative;
}

.scroll-container {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  margin-bottom: 100px;
}

.scrollable-image {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.scroll-container::-webkit-scrollbar {
  width: 0.5em;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.scrollable-image {
  width: auto;
  max-width: none;
  min-height: 400px;
  min-width: 400px;
  box-shadow: none;
  margin-right: 20px;
}

.rotate-container {
  z-index: 2;
  top: -56px;
  left: 0;
  right: 0;
  position: absolute;
  max-width: 1240px;
  margin: 0 auto;
}

.rotate-element {
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5%;

  svg {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 767px) {
  .scrollable-image {
    width: auto;
    min-height: 300px;
    min-width: 300px;
  }

  .rotate-element {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 575px) {
  .scrollable-image {
    width: 200px;
    height: 200px;
    min-height: 200px;
    min-width: 200px;
  }

  .rotate-element {
    width: 100px;
    height: 100px;
  }
}

// marquee

.marquee-main {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 120px;
  background-color: black;
}

.marquee--inner {
  display: block;
  width: 200%;
  position: absolute;
  animation: marquee 30s linear infinite;

  span {
    float: left;
    width: 50%;
    overflow: hidden;

    div {
      display: flex;
      width: 100%;
      justify-content: space-around;
    }
  }
}

@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

@media (max-width: 1100px) {
  .marquee--inner span div {
    justify-content: space-around;
  }

  .marquee--inner span div img {
    height: 60px;
  }

  .marquee-main {
    height: 80px;
  }
}

@media (max-width: 991px) {
  .marquee--inner:hover {
    animation-play-state: running;
  }
}

@media (max-width: 670px) {
  .marquee--inner {
    width: 400%;
  }
}
