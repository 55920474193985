/** @format */

// Tailwindcss Colors

$clr1: #ebc7b1;
$clr2: #ebc7b1;
$clr3: #d27941;
$clr4: #fbe8de;
$clr5: #4ca96d;
$clr6: #489b65;

section.error404 {
  cursor: default;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fbe8de;
  user-select: none;

  .text-container {
    position: relative;
    z-index: 1;
    margin-top: 5rem;
    text-align: center;

    h1 {
      color: #000;
      font-family: "gr";
      font-weight: 700;
      font-size: 5rem;
      margin-bottom: 1rem;
    }
    p {
      color: #000;
      font-weight: 500;
      letter-spacing: 0.05rem;
      margin: 0.5rem 0;
      font-family: "jj";
      user-select: text;
    }
    a {
      font-family: "gr";
      color: #000;
    }
  }

  svg {
    background-color: $clr2;
    width: 100%;
    overflow: visible;

    > path:nth-child(1) {
      fill: $clr1;
      stroke: $clr3;
    }

    > path:nth-child(2) {
      fill: $clr4;
    }

    > path:nth-child(6) {
      fill: $clr3;
    }

    text {
      fill: #000;
      font-size: 660px;
      font-weight: 800;
    }

    > path:nth-child(8) {
      fill: $clr4;
    }
  }
}

.cactus {
  path:nth-child(1) {
    fill: $clr5;
  }
  path:nth-child(2) {
    fill: $clr6;
  }
  path:nth-child(3) {
    fill: black;
  }
}

@media (max-width: 767px) {
  section.error404 .text-container {
    margin-top: 1rem;
  }

  section.error404 {
    row-gap: 120px;
  }
}

@media (max-width: 575px) {
  section.error404 .text-container h1 {
    font-size: 64px;
  }

  section.error404 svg path {
    transform: none !important;
  }

  .svg404 {
    display: none;
  }

  .first404 {
    display: none;
  }

  section.error404 {
    background-image: url(./icons/noise.png), linear-gradient(#fbe8de, #fbe8de);
    background-position: 0 0, 0 0;
    background-size: auto, auto;
    width: 100%;
    position: relative;
  }
}
