/** @format */
nav {
  background-color: black;
  border: 1px solid #2b2b2b;
  position: fixed;
  bottom: 29px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 100px;
  width: fit-content;
  padding: 6px;
  display: flex;
  align-items: center;
  z-index: 10;

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      a {
        font-family: "jj", sans-serif;
        font-feature-settings: "case";
        font-weight: 600;
        font-size: 14px;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.4s;
        border-radius: 10px;
        height: 56px;
        padding: 0 20px;
        color: white;
        text-decoration: none;
      }

      a.active {
        background-color: white;
        color: #000;
        border-radius: 60px;
      }

      &:first-child {
        a {
          border-radius: 70px;
          background-color: #2c2c2c;
          content: url(./icons/arrow-up.svg);
          width: 77.73px;
          padding: 15px 0;
          transition: 0.5s;

          &:hover {
            background-color: #424242;
          }
        }
      }
    }
  }
}

.login-navlink {
  a {
    height: 56px;
    padding: 0px 20px;
    border-radius: 70px;
    background-color: #2c2c2c;
    color: white;
    font-size: 14px;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: capitalize;
    font-family: "jj";
    font-feature-settings: "case";
    font-weight: 600;

    &:hover {
      background-color: #424242;
    }
  }
}
