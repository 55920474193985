/** @format */
section#section7 {
  background-image: url(./icons/noise.png), linear-gradient(#e1eaf4, #e1eaf4);
  background-position: 0 0, 0 0;
  background-size: auto, auto;
  width: 100%;
  position: relative;
}

.main-div.sct7 div a {
  margin-top: 40px;
}

.sct7-grid {
  max-width: 1240px;
  margin: 0 auto;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
  margin-top: 60px;
  margin-bottom: 60px;

  img {
    width: 100%;
  }
}

.sct7-logos {
  max-width: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
}

.sct7svg1 {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -75px;
}

.sct7svg2 {
  width: 150px;
  position: absolute;
  top: -60px;
  right: 0;
}

@media (max-width: 767px) {
  .sct7svg2 {
    width: 100px;
    position: absolute;
    top: -60px;
    right: 0;
  }
}

@media (max-width: 575px) {
  .sct7svg1 {
    top: -55px;
  }
  .sct7svg2 {
    display: none;
  }
  .main-div.sct7 {
    div {
      a {
        margin-top: 30px;
      }
    }
  }

  .sct7-grid {
    grid-template-columns: 1fr;
  }

  .sct7-logos {
    max-width: 1240px;
    margin: 0 auto;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
    margin-top: 20px;
    margin-bottom: 20px;

    img {
      margin: 0 auto;
    }
  }
}
