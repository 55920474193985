/** @format */

section#section11 {
  background-image: url(./icons/noise.png), linear-gradient(#fff, #fff);
  background-position: 0 0, 0 0;
  background-size: auto, auto;
  width: 100%;
  position: relative;
}

.arrow svg {
  width: 16px;
  height: 16px;
}

.faq-section {
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
  margin-top: 60px;
}

.faq-item {
  border-bottom: 1px solid #000;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.faq-question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  font-family: "jj";
}

.arrow {
  transition: transform 0.3s ease;
}

.arrow.active {
  transform: rotate(180deg);
}

.faq-answer {
  max-height: 0;
  opacity: 0;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  transition: 0.5s;
  font-size: 17px;
  line-height: 26px;
}

.faq-item.active .faq-answer {
  padding-top: 10px;
  max-height: 1000px;
  opacity: 1;
  font-family: "jj";
}

.sct11svg1 {
  position: absolute;
  left: -8%;
  top: -220px;
  width: 240px;
}

.sct11svg2 {
  position: absolute;
  right: 0;
  top: -60px;
}

@media (max-width: 991px) {
  .sct11svg1 {
    width: 170px;
    top: -133px;
  }

  .sct11svg2 {
    width: 110px;
  }
}

@media (max-width: 767px) {
  .sct11svg1 {
    width: 140px;
    top: -162px;
  }

  .sct11svg2 {
    width: 80px;
  }
}

@media (max-width: 575px) {
  .sct11svg1 {
    width: 70px;
    top: -71px;
  }

  .sct11svg2 {
    top: -40px;
    width: 60px;
  }
}
