/** @format */

section#section5 {
  background-image: url(./icons/noise.png), linear-gradient(#e1eaf4, #e1eaf4);
  background-position: 0 0, 0 0;
  background-size: auto, auto;
  width: 100%;
  position: relative;
}
.sct5-layout {
  background-color: #fff;
  background-image: url(./icons/noise.png);
  background-position: 0 0;
  background-size: auto;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 700;

  p {
    font-size: 32px;
    line-height: 40px;
  }

  svg {
    margin-bottom: 30px;
  }
}

.sct5-layout2 {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

.sct5-div {
  margin: 0 auto;
  max-width: 1240px;
  margin-top: 100px;
  column-gap: 60px;
  row-gap: 60px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.sct5-qt {
  display: flex;
  align-items: center;
  font-family: "jj";
  margin-top: 20px;

  p {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 12px;

    strong {
      margin-left: 6px;
      font-family: "jj", sans-serif;
    }
  }
}

.sct5-qt-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.sct5svg1 {
  width: 180px;
  max-width: none;
  position: absolute;
  top: -97px;
  left: 0%;
}

.sct5svg2 {
  position: absolute;
  top: -75px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.sct5svg3 {
  width: 230px;
  max-width: none;
  position: absolute;
  top: -192px;
  right: 0;
}

@media (max-width: 991px) {
  .sct5svg1 {
    width: 130px;
    top: -123px;
  }

  .sct5svg3 {
    width: 170px;
    top: -120px;
  }
}

@media (max-width: 767px) {
  .sct5svg3 {
    width: 140px;
  }
  .sct5-layout {
    padding: 40px 20px;
  }

  .sct5-div {
    display: flex;
    flex-direction: column;
  }

  .sct5svg1 {
    width: 100px;
    top: -95px;
  }
}

@media (max-width: 575px) {
  .sct5svg1 {
    width: 80px;
    top: -83px;
  }

  .sct5svg2 {
    top: -85px;
  }

  .sct5svg3 {
    top: -80px;
    width: 100px;
  }

  .sct5-layout svg {
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .sct5-layout p {
    text-align: center;
  }

  .sct5-div {
    margin-top: 60px;
  }

  .sct5 {
    padding-top: 90px;
  }

  .sct5-qt {
    flex-direction: column;

    p {
      margin: 0;
      margin-top: 12px;
    }
  }
}
