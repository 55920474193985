/** @format */

section#section6 {
  background-image: url(./icons/noise.png), linear-gradient(#fff, #fff);
  background-position: 0 0, 0 0;
  background-size: auto, auto;
  width: 100%;
  position: relative;
}

.main-div.sct6 {
  padding-top: 120px;
  div {
    a {
      margin-top: 40px;
    }
  }
}

.sct6-grid {
  margin: 0 auto;
  display: grid;
  margin-top: 60px;
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  text-align: left;
  position: relative;
}

.sct6-grid {
  max-width: 1240px;
  .notmal-txt-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 320px;
    margin: 0 auto;
    font-family: "jj";

    p {
      text-align: center;
    }
  }
}

.sct6-svg {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infi-svg {
  width: 80px;
  height: 80px;
  path {
    animation: infinity-load 5s infinite cubic-bezier(0.65, 0.05, 0.36, 1);
  }
}

.speed-svg {
  width: 80px;
  height: 80px;
  path {
    animation: splash 3s cubic-bezier(0.165, 0.84, 0.44, 1) infinite both;
    transform-origin: center center;
  }
}

@keyframes splash {
  0% {
    transform: scale(0.2);
    opacity: 1;
  }
  80% {
    transform: scale(1.2);
    opacity: 1;
  }
  to {
    transform: scale(2.2);
    opacity: 0;
  }
}

@keyframes infinity-load {
  to {
    stroke-dashoffset: 10;
  }
}

.fees-svg {
  width: 80px;
  height: 80px;

  .fees-path {
    animation: rotate 5s linear both infinite;
    transform-origin: center center;
  }
}

@keyframes jello {
  0%,
  to {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
}

.str-svg {
  width: 80px;
  height: 80px;
  path {
    animation: jello 2s both infinite cubic-bezier(0.69, 0.34, 0.09, 0.6);
    transform-origin: center;
  }
}

.slidersss {
  animation: slidersss 2s cubic-bezier(0.86, 0, 0.07, 1) infinite alternate both;
}
@keyframes slidersss {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(10px);
  }
}
.slidersss-2 {
  animation: slidersss-2 2s cubic-bezier(0.86, 0, 0.07, 1) infinite alternate
    both;
}
@keyframes slidersss-2 {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-10px);
  }
}

.st-svg {
  width: 80px;
  height: 80px;
}

@keyframes pulsate {
  0%,
  to {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
}

.hrt-svg {
  width: 80px;
  height: 80px;

  .hrt2-svg {
    animation: pulsate 2s ease-in-out infinite both;
    transform-origin: center center;
  }
}

.sct6svg1 {
  position: absolute;
  top: -102px;
  left: 0;
  width: 190px;
}

.sct6svg2 {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -53px;
}

.sct6-mrg {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .sct6-grid {
    grid-template-columns: 1fr;
  }

  .sct6svg1 {
    width: 150px;
    top: -102px;
  }
}

@media (max-width: 575px) {
  .main-div.sct6 {
    div {
      a {
        margin-top: 30px;
      }
    }
  }

  .sct6svg1 {
    width: 110px;
    top: -67px;
  }
}
