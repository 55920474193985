/** @format */

* {
  margin: 0;
  padding: 0;
}

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  margin: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

.header {
  width: 100%;
  overflow-x: hidden;
}

.h2title {
  font-size: 50px;
  // font-weight: 200;
  line-height: 60px;
  letter-spacing: 0.5px;
  margin-top: 0;
  text-align: center;
}

.h2subtitle {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  font-family: "jj", sans-serif;
}

.maxwidth600 {
  max-width: 540px;
}

.title-div {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

.main-div {
  padding: 150px 5% 100px;
}

.normal-title {
  font-size: 20px;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
  font-weight: 700;
  text-transform: capitalize;
  font-family: "jj", sans-serif;
  font-feature-settings: "case";
}

.text-align-center {
  text-align: center;
}

.normal-txt {
  font-size: 17px;
  line-height: 24px;
  font-family: "jj", sans-serif;
}

.title-underline {
  background-image: url(./icons/underline.svg);
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

.sct7-logos {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.maxwidth800 {
  max-width: 800px;
}

@media (max-width: 767px) {
  .h2title {
    font-size: 50px;
    line-height: 60px;
  }
}

@media (max-width: 575px) {
  .h2title {
    font-size: 42px;
    line-height: 44px;
  }
}

.logo-line {
  position: absolute;
  margin-right: 20px;
  fill: black;
}

.line-text-div {
  width: 0px;
  overflow: hidden;
}

.line-text {
  fill: black;
}

.rslq {
  margin-top: -1px;
  width: 1px;
  height: 1px;
  background-color: transparent;
  bottom: 0;
  left: 0;
  z-index: 100;
}
