/** @format */

section#section10 {
  background-image: url(./icons/noise.png), linear-gradient(#fbe8de, #fbe8de);
  background-position: 0 0, 0 0;
  background-size: auto, auto;
  width: 100%;
  position: relative;
}

.main-div.sct10 {
  padding-bottom: 160px;
}

.sct10-main-grid {
  max-width: 1240px;
  margin: 0 auto;
  display: grid;
  grid-row-gap: 16px;
  grid-column-gap: 2px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 60px;
  position: relative;
}

.sct10-main-item-top {
  position: relative;
  text-align: left;
  background-color: #fff;
  background-image: url(./icons/noise.png);
  background-position: 0 0;
  background-size: auto;
  padding: 60px 30px 30px;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sct10-top-title {
  min-height: 450px;
  text-align: left;
  background-color: #fff;
  background-image: url(./icons/noise.png);
  background-position: 0 0;
  background-size: auto;
  flex-direction: column;
  justify-content: space-between;
  padding: 60px 30px 30px;
  display: flex;
  position: relative;
}

.sct10-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

.sct10-subtitle {
  font-size: 15px;
  line-height: 22px;
  margin-top: 10px;
  font-family: "jj";
}

.sct10-top-item-bottom {
  font-family: "jj";
  font-feature-settings: "case";
  text-align: center;

  .main-button {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.onp {
  margin: 0 auto;
  max-width: 1300px;
  position: relative;
}

section#only-pricing {
  position: relative;

  .link-back {
    background-color: black;
    border-radius: 8px;
    position: absolute;
    top: 60px;
    left: 100px;
    z-index: 2;
    padding: 10px 20px;
  }
}

.sct10-price {
  margin-top: 30px;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.5px;
  text-align: left;
  font-weight: 800;
  font-family: "jj";
  font-feature-settings: normal;
}

.sct10-price-subtitle {
  text-align: left;
  opacity: 0.5;
  font-size: 12px;
  margin-bottom: 40px;
  font-family: "jj";
  font-feature-settings: normal;
}

.book-a-call-button {
  display: inline-block;
  width: auto;
  color: #000;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border: 1px #000;
  border-bottom-style: dotted;
  font-weight: 700;
  text-decoration: none;
}

.sct10-main-item-bottom {
  min-height: 350px;
  text-align: left;
  background-image: url(./icons/noise.png), linear-gradient(#fff, #fff);
  background-position: 0 0, 0 0;
  background-size: auto, auto;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2px;
  padding: 30px;
  display: block;
}

.sct10-item-bottom {
  text-align: left;
  font-size: 14px;
}

.sct10-bottom-title {
  font-weight: 700;
  font-family: "jj";
}

.sct10-bottom-ul {
  font-family: "jj";
  margin-top: 20px;
  padding-left: 16px;
  margin-bottom: 10px;

  li {
    margin-bottom: 10px;
    font-size: 14px;
    list-style-type: disc;
  }
}

.sct10-main-grid .sct10-main-item:last-child {
  padding: 32px;
  background-color: #fff;
  background-image: url(./icons/noise.png);
  background-position: 0 0;
  background-size: auto;
}

.sct10-r-top {
  border-bottom: 1px solid #000;
  padding-bottom: 40px;
  font-family: "jj";
  font-weight: 700;

  div {
    text-align: center;
    img {
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }

  a {
    text-decoration: none;
    color: #000;
    margin-top: 20px;
    font-weight: 700;
  }
}

.sct10-r-top .sct10-subtitle {
  margin-bottom: 20px;
  font-family: "jj";
  font-weight: 400;
}

.sct10-r-bottom {
  margin-top: 40px;
  font-family: "jj";
  font-weight: 700;

  img {
    width: 100%;
    margin-bottom: 20px;
  }

  a {
    text-decoration: none;
    color: #000;
    margin-top: 20px;
    font-weight: 700;
  }
}

.sct10-r-bottom .sct10-subtitle {
  margin-bottom: 20px;
  font-family: "jj";
  font-weight: 400;
}

.sct10-addon {
  max-width: 1240px;
  margin: 0 auto;
  background-color: black;
  padding: 50px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: "jj", sans-serif;
  font-feature-settings: "case";

  div {
    display: inline-block;
    background-color: #fdd900;
    color: #000;
    border-radius: 32px;
    margin-bottom: 24px;
    padding: 6px 14px;
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    border: 1px #b3bbe5;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 0.5px;
  }

  p {
    margin-top: 6px;
    font-size: 15px;
    line-height: 22px;
  }

  h3 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 30px;
    line-height: 30px;
    font-feature-settings: normal;
  }
}

.sct10svg1 {
  position: absolute;
  left: 0;
  top: -50px;
}

.sct10svg2 {
  position: absolute;
  left: 0;
  right: 0;
  top: -53px;
  margin: 0 auto;
}

.sct10svg3 {
  position: absolute;
  right: 0;
  top: -60px;
}

.sct10-tag {
  position: absolute;
  top: -3%;
  left: 9%;
  font-size: 10px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 1px;
  background-color: #ea552b;
  border-radius: 5px;
  padding: 6px 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-family: "jj";
  transform: rotate(-9deg);

  div {
    padding-top: 7px;
    padding-bottom: 6px;
    font-feature-settings: "case";
  }
}

.sct10svg5 {
  position: absolute;
  width: 20px;
  top: -10%;
  left: 22%;
}

.sct10-r-top .sct10-title {
  font-feature-settings: "case";
}

.sct10-r-bottom .sct10-title {
  font-feature-settings: "case";
}

.footer-social-links {
  display: flex;
  flex-direction: row !important;
  column-gap: 10px;
  margin-left: -2px;
  a {
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 32px;
      height: 32px;
    }
  }
}

@media (max-width: 1070px) {
  .sct10-tag {
    top: -8%;
    left: 4%;
  }

  .sct10svg5 {
    top: -19%;
    left: 8%;
  }

  .sct10-main-grid {
    grid-template-columns: 1fr;
  }

  .sct10-top-item-bottom {
    max-width: 188px;
  }

  .sct10-main-item-bottom {
    min-height: 0;
  }

  .sct10-main-item-top {
    min-height: 0;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
  }

  .sct10-price-subtitle {
    margin-bottom: 30px;
  }

  .sct10-top-item-bottom {
    text-align: left;
  }

  .sct10-main-grid .sct10-main-item:last-child {
    background-color: transparent;
    background-image: none;
  }

  .sct10-r-top {
    text-align: center;
  }

  .sct10-r-bottom {
    text-align: center;
  }

  .sct10-r-bottom img {
    width: auto;
    height: 100%;
  }
}

.sct10svg4-div {
  margin-top: 60px;
  max-width: 373px;
}

.sct10svg4 {
  width: 100%;
}

@media (max-width: 991px) {
  .sct10svg1 {
    top: -16px;
    width: 110px;
  }
}

@media (max-width: 767px) {
  .sct10svg1 {
    top: -72px;
    width: 90px;
  }

  .sct10svg3 {
    width: 120px;
  }
}

@media (max-width: 575px) {
  .sct10-top-item-bottom {
    max-width: 100%;
  }

  .sct10svg3 {
    width: 100px;
    top: -40px;
  }

  .main-div.sct10 {
    padding-bottom: 100px;
  }

  .sct10svg5 {
    top: -17%;
    left: 8%;
  }

  section#only-pricing .link-back {
    top: 60px;
    left: 30px;
  }
}
