/** @format */

ul {
  padding: 0;
  margin: 0;
}
body {
  transition: 0.5s;
}

a {
  cursor: pointer;
}

li {
  list-style: none;
}
.header {
  overflow: inherit;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 32px;
  width: 100%;
  color: #292a2a;
  z-index: 4;
}
.header a {
  color: inherit;
  text-decoration: none;
}
.header .navContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  max-width: 1230px;
  margin: 0 auto;
  align-items: center;
}

.logo-image {
  height: 52px;
  width: auto;
}

.navToggle {
  display: block;
  background-color: transparent;
  border: none;
  height: 64px;
  width: 64px;
  padding: 8px 8px;
  outline: none;
  cursor: pointer;
  z-index: 5;
  padding-top: 0px;
}
.navToggle.open span:first-child {
  transform: rotate(45deg) translate(2px, 2.4px);
}
.navToggle.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}
.navToggle.open span:last-child {
  transform: rotate(-45deg) translate(4.4px, -4.4px);
}
.navToggle span {
  position: relative;
  display: block;
  width: 15px;
  height: 2px;
  margin: 0 auto;
  margin-bottom: 2.5px;
  background-color: #000;
  border-radius: 1px;
  transition: all 0.3s cubic-bezier(1, 0.13, 0.35, 1.09);
}
.navToggle.open span {
  background: #333;
}

.navToggle span:last-child {
  margin-bottom: 0;
}
.wrapper {
  z-index: -1;
  position: fixed;
  height: 100vh;
  background-image: url(./icons/noise.png), linear-gradient(#fbe8de, #fbe8de);
  background-position: 0 0, 0 0;
  background-size: auto, auto;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
}

.mainNav {
  top: 0;
  right: 0;
  width: 200px;
  height: 100vh;
  background-color: #f4f4f4;
  padding: 78px 15px 58px 15px;
  z-index: 3;
  transform: translateX(100%);
  transition: transform 0.25s ease;
  overflow-y: auto;
  position: fixed;
}
.mainNav.open {
  transform: translateX(0%);
}
.mainNav .mainNavLink {
  display: block;
  padding: 0.71875rem 0;
  text-transform: capitalize;
  transition: 0.5s;
  font-family: "jj";
  font-feature-settings: "case";
  font-weight: 600;

  &:active {
    color: red;
    // position: -webkit-sticky;
    // position: sticky;
    // top: 0;
  }
}
//
.overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  height: 0;
  width: 0;
  background-color: rgba(226, 225, 226, 0.63);
  opacity: 0;
  transition: opacity 0.5s ease 0.1s;
}
.overlay.open {
  opacity: 3;
  width: 100%;
  height: 120%;
}

@media (max-width: 991px) {
  nav {
    display: none;
  }

  .header {
    padding-top: 22px;
  }
}

@media (min-width: 992px) {
  .navToggle {
    display: none;
  }

  .header .navContainer {
    justify-content: center;
  }

  .header .navContainer {
    padding-right: 40px;
  }
}

@media (max-width: 575px) {
  .rsremove {
    display: none;
  }
  .logo-image {
    height: 42px;
  }

  .header .navContainer {
    padding-right: 20px;
  }
}

@-webkit-keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 165.7519989013672px;
    stroke-dasharray: 165.7519989013672px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 165.7519989013672px;
  }
}

@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 165.7519989013672px;
    stroke-dasharray: 165.7519989013672px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 165.7519989013672px;
  }
}

.bounce-logo-1 {
  -webkit-animation: animate-svg-stroke-1 1s cubic-bezier(0.47, 0, 0.745, 0.715)
      0s both,
    animate-svg-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
  animation: animate-svg-stroke-1 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
    animate-svg-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
}

@-webkit-keyframes animate-svg-stroke-2 {
  0% {
    stroke-dashoffset: 103.46399688720703px;
    stroke-dasharray: 103.46399688720703px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 103.46399688720703px;
  }
}

@keyframes animate-svg-stroke-2 {
  0% {
    stroke-dashoffset: 103.46399688720703px;
    stroke-dasharray: 103.46399688720703px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 103.46399688720703px;
  }
}

.bounce-logo-2 {
  -webkit-animation: animate-svg-stroke-2 1s cubic-bezier(0.47, 0, 0.745, 0.715)
      0.1s both,
    animate-svg-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.91s both;
  animation: animate-svg-stroke-2 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s
      both,
    animate-svg-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.91s both;
}

@-webkit-keyframes animate-svg-stroke-3 {
  0% {
    stroke-dashoffset: 218.18707275390625px;
    stroke-dasharray: 218.18707275390625px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 218.18707275390625px;
  }
}

@keyframes animate-svg-stroke-3 {
  0% {
    stroke-dashoffset: 218.18707275390625px;
    stroke-dasharray: 218.18707275390625px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 218.18707275390625px;
  }
}

@-webkit-keyframes animate-svg-fill-3 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(242, 146, 81);
  }
}

@keyframes animate-svg-fill-3 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(242, 146, 81);
  }
}

.bounce-logo-3 {
  -webkit-animation: animate-svg-stroke-3 1s cubic-bezier(0.47, 0, 0.745, 0.715)
      0.2s both,
    animate-svg-fill-3 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.02s both;
  animation: animate-svg-stroke-3 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s
      both,
    animate-svg-fill-3 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.02s both;
}

@-webkit-keyframes animate-svg-stroke-4 {
  0% {
    stroke-dashoffset: 218.18707275390625px;
    stroke-dasharray: 218.18707275390625px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 218.18707275390625px;
  }
}

@keyframes animate-svg-stroke-4 {
  0% {
    stroke-dashoffset: 218.18707275390625px;
    stroke-dasharray: 218.18707275390625px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 218.18707275390625px;
  }
}

@-webkit-keyframes animate-svg-fill-4 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(242, 146, 81);
  }
}

@keyframes animate-svg-fill-4 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(242, 146, 81);
  }
}

.bounce-logo-4 {
  -webkit-animation: animate-svg-stroke-4 1s cubic-bezier(0.47, 0, 0.745, 0.715)
      0.30000000000000004s both,
    animate-svg-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      1.1300000000000001s both;
  animation: animate-svg-stroke-4 1s cubic-bezier(0.47, 0, 0.745, 0.715)
      0.30000000000000004s both,
    animate-svg-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      1.1300000000000001s both;
}

@-webkit-keyframes animate-svg-stroke-5 {
  0% {
    stroke-dashoffset: 194.92092895507812px;
    stroke-dasharray: 194.92092895507812px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 194.92092895507812px;
  }
}

@keyframes animate-svg-stroke-5 {
  0% {
    stroke-dashoffset: 194.92092895507812px;
    stroke-dasharray: 194.92092895507812px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 194.92092895507812px;
  }
}

@-webkit-keyframes animate-svg-fill-5 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(242, 146, 81);
  }
}

@keyframes animate-svg-fill-5 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(242, 146, 81);
  }
}

.bounce-logo-5 {
  -webkit-animation: animate-svg-stroke-5 1s cubic-bezier(0.47, 0, 0.745, 0.715)
      0.4s both,
    animate-svg-fill-5 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.24s both;
  animation: animate-svg-stroke-5 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s
      both,
    animate-svg-fill-5 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.24s both;
}

@-webkit-keyframes animate-svg-stroke-6 {
  0% {
    stroke-dashoffset: 426.5301818847656px;
    stroke-dasharray: 426.5301818847656px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 426.5301818847656px;
  }
}

@keyframes animate-svg-stroke-6 {
  0% {
    stroke-dashoffset: 426.5301818847656px;
    stroke-dasharray: 426.5301818847656px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 426.5301818847656px;
  }
}

@-webkit-keyframes animate-svg-fill-6 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-6 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.bounce-logo-6 {
  -webkit-animation: animate-svg-stroke-6 1s cubic-bezier(0.47, 0, 0.745, 0.715)
      0.5s both,
    animate-svg-fill-6 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.35s both;
  animation: animate-svg-stroke-6 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s
      both,
    animate-svg-fill-6 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.35s both;
}

@-webkit-keyframes animate-svg-stroke-7 {
  0% {
    stroke-dashoffset: 322.96905517578125px;
    stroke-dasharray: 322.96905517578125px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 322.96905517578125px;
  }
}

@keyframes animate-svg-stroke-7 {
  0% {
    stroke-dashoffset: 322.96905517578125px;
    stroke-dasharray: 322.96905517578125px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 322.96905517578125px;
  }
}

@-webkit-keyframes animate-svg-fill-7 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-7 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.bounce-logo-7 {
  -webkit-animation: animate-svg-stroke-7 1s cubic-bezier(0.47, 0, 0.745, 0.715)
      0.6000000000000001s both,
    animate-svg-fill-7 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.46s both;
  animation: animate-svg-stroke-7 1s cubic-bezier(0.47, 0, 0.745, 0.715)
      0.6000000000000001s both,
    animate-svg-fill-7 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.46s both;
}

@-webkit-keyframes animate-svg-stroke-8 {
  0% {
    stroke-dashoffset: 493.08636474609375px;
    stroke-dasharray: 493.08636474609375px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 493.08636474609375px;
  }
}

@keyframes animate-svg-stroke-8 {
  0% {
    stroke-dashoffset: 493.08636474609375px;
    stroke-dasharray: 493.08636474609375px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 493.08636474609375px;
  }
}

@-webkit-keyframes animate-svg-fill-8 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-8 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.bounce-logo-8 {
  -webkit-animation: animate-svg-stroke-8 1s cubic-bezier(0.47, 0, 0.745, 0.715)
      0.7000000000000001s both,
    animate-svg-fill-8 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.57s both;
  animation: animate-svg-stroke-8 1s cubic-bezier(0.47, 0, 0.745, 0.715)
      0.7000000000000001s both,
    animate-svg-fill-8 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.57s both;
}

@-webkit-keyframes animate-svg-stroke-9 {
  0% {
    stroke-dashoffset: 430.97357177734375px;
    stroke-dasharray: 430.97357177734375px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 430.97357177734375px;
  }
}

@keyframes animate-svg-stroke-9 {
  0% {
    stroke-dashoffset: 430.97357177734375px;
    stroke-dasharray: 430.97357177734375px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 430.97357177734375px;
  }
}

@-webkit-keyframes animate-svg-fill-9 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-9 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.bounce-logo-9 {
  -webkit-animation: animate-svg-stroke-9 1s cubic-bezier(0.47, 0, 0.745, 0.715)
      0.8s both,
    animate-svg-fill-9 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      1.6800000000000002s both;
  animation: animate-svg-stroke-9 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s
      both,
    animate-svg-fill-9 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      1.6800000000000002s both;
}

@-webkit-keyframes animate-svg-stroke-10 {
  0% {
    stroke-dashoffset: 135.95343017578125px;
    stroke-dasharray: 135.95343017578125px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 135.95343017578125px;
  }
}

@keyframes animate-svg-stroke-10 {
  0% {
    stroke-dashoffset: 135.95343017578125px;
    stroke-dasharray: 135.95343017578125px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 135.95343017578125px;
  }
}

@-webkit-keyframes animate-svg-fill-10 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(242, 146, 81);
  }
}

@keyframes animate-svg-fill-10 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(242, 146, 81);
  }
}

.bounce-logo-10 {
  -webkit-animation: animate-svg-stroke-10 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both,
    animate-svg-fill-10 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.79s both;
  animation: animate-svg-stroke-10 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s
      both,
    animate-svg-fill-10 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.79s both;
}

@-webkit-keyframes animate-svg-stroke-11 {
  0% {
    stroke-dashoffset: 432.478271484375px;
    stroke-dasharray: 432.478271484375px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 432.478271484375px;
  }
}

@keyframes animate-svg-stroke-11 {
  0% {
    stroke-dashoffset: 432.478271484375px;
    stroke-dasharray: 432.478271484375px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 432.478271484375px;
  }
}

@-webkit-keyframes animate-svg-fill-11 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-11 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.bounce-logo-11 {
  -webkit-animation: animate-svg-stroke-11 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 1s both,
    animate-svg-fill-11 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      1.9000000000000001s both;
  animation: animate-svg-stroke-11 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1s
      both,
    animate-svg-fill-11 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      1.9000000000000001s both;
}

@-webkit-keyframes animate-svg-stroke-12 {
  0% {
    stroke-dashoffset: 397.98431396484375px;
    stroke-dasharray: 397.98431396484375px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 397.98431396484375px;
  }
}

@keyframes animate-svg-stroke-12 {
  0% {
    stroke-dashoffset: 397.98431396484375px;
    stroke-dasharray: 397.98431396484375px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 397.98431396484375px;
  }
}

@-webkit-keyframes animate-svg-fill-12 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-12 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.bounce-logo-12 {
  -webkit-animation: animate-svg-stroke-12 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both,
    animate-svg-fill-12 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.01s both;
  animation: animate-svg-stroke-12 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s
      both,
    animate-svg-fill-12 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.01s both;
}
