/** @format */

section#login {
  background-image: url(./icons/noise.png), linear-gradient(#fbe8de, #fbe8de);
  background-position: 0 0, 0 0;
  background-size: auto, auto;
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.main-login-div {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin: 20px;
  max-width: 482px;
  width: 100%;
}

.login-upper {
  background-image: url(./icons/noise.png), linear-gradient(#f4f4f0, #f4f4f0);
  background-position: 0 0, 0 0;
  background-size: auto, auto;

  padding: 0 4px;
  max-width: 482px;
  width: 100%;
  height: 60px;
  border-radius: 4px;
  border: 1.5px solid #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-back {
  padding: 0 20px;
  height: 50px;
  border-radius: 4px;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 1.5px solid transparent;

  &:hover {
    background: #e5e5e0;
    border: 1.5px solid #cecece;
  }
}

.login-go {
  padding: 0 20px;
  height: 50px;
  border-radius: 4px;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 1.5px solid transparent;
  color: black;
  text-decoration: none;
  font-family: "jj";
  font-weight: 500;
  padding-bottom: 3px;

  &:hover {
    background: #e5e5e0;
    border: 1.5px solid #cecece;
  }
}

.login-down {
  max-width: 482px;
  width: 100%;
  height: 502px;
  border-radius: 4px;
  background-image: url(./icons/noise.png), linear-gradient(#f4f4f0, #f4f4f0);
  background-position: 0 0, 0 0;
  background-size: auto, auto;

  border: 1.5px solid #000;
  padding: 47px 24px 57px 24px;
}

.login-down.termss {
  height: 480px;
}

.login-title {
  font-family: "gr";
  font-weight: 700;
  font-size: 38px;
  text-align: left;
  color: #000;
  text-align: center;
  width: 100%;
  margin-bottom: 32px;
}

.login-labels {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  label {
    p {
      font-family: "jj", sans-serif;
      font-weight: 500;
      font-size: 21px;
      text-align: left;
      color: #000;
      margin-bottom: 8px;
    }

    input {
      width: 100%;
      height: 60px;
      border-radius: 4px;
      background: #fff;
      border: 1.5px solid #000;
      padding: 0 20px;
      padding-bottom: 2px;
      font-family: "jj", sans-serif;
      font-weight: 400;
      font-size: 21px;
      text-align: left;
      color: #000;

      &:focus-visible {
        outline-offset: 0.5px;
        outline-color: black;
      }
    }
  }
}

.login-button {
  text-decoration: none;
  z-index: 2;
  width: 100%;
  height: 60px;
  border-radius: 4px;
  background: #000;
  border: 1.5px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1px;
  color: white;
  font-size: 22px;
  font-weight: 600;
  margin-top: 55px;
  cursor: pointer;
  transition: all 0.14s ease-out 0s;
  font-family: "jj";
  font-feature-settings: "case";

  &:hover {
    transform: translate(-0.25rem, -0.25rem);
    box-shadow: 0.25rem 0.25rem 0 #ea552b;
  }
}

.lg1 {
  position: absolute;
  top: -38px;
  right: -28px;
  width: 55px;
}

.lg2 {
  position: absolute;
  top: 82px;
  left: -46px;
  width: 70px;
}

.lg3 {
  position: absolute;
  top: 409px;
  right: -96px;
  width: 192px;
  z-index: 1;
}

.lg4 {
  position: absolute;
  top: 68px;
  right: -26px;
  width: 54px;
}

.lg5 {
  position: absolute;
  top: 487px;
  left: -62px;
  width: 124px;
}

.lg6 {
  position: absolute;
  top: 52px;
  right: 13px;
}

.lg7 {
  position: absolute;
  top: 167px;
  right: 13px;
}

.login-text {
  font-family: "jj", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  color: #000;
  max-width: 383px;
  margin: 0 auto;
}

.login-button.signup {
  max-width: 229px;
  margin: 0 auto;
  margin-top: 55px;
}

.login-down.ppacks {
  height: 582px;
  padding: 0 24px;
}

.ppack-go {
  top: 16px;
  left: 16px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  width: 80px;
  height: 47px;
  transition: 0.5s;
  border: 1px solid transparent;

  &:hover {
    background-color: #e5e5e0;
    border: 1px solid #cecece;
  }
}

.iban-input {
  width: 100%;
  height: 60px;
  border-radius: 4px;
  background: #fff;
  border: 1.5px solid #000;
  padding: 0 18px;
  font-family: "jj", sans-serif;
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  color: #000;

  &:focus-visible {
    outline-offset: 0.5px;
    outline-color: black;
  }
}

.main-iban {
  display: flex;
  flex-direction: column;
  row-gap: 22px;
}

.iban-icon {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 8px;

  p {
    font-family: "jj";
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    color: #000;
  }
}

.iban-div {
  display: flex;
  column-gap: 11px;
}

.iban-button {
  cursor: pointer;
  border-radius: 4px;
  min-width: 60px;
  height: 60px;
  border: 1px solid transparent;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.14s ease-out 0s;

  &:hover {
    transform: translate(-0.25rem, -0.25rem);
    box-shadow: 0.25rem 0.25rem 0 #ea552b;
  }
}

.popup-content {
  background-image: url(./icons/noise.png), linear-gradient(#f4f4f0, #f4f4f0);
  background-position: 0 0, 0 0;
  background-size: auto, auto;
  border: 1.5px solid #000;
  height: 582px;
  max-width: 482px;
  width: 100%;
  position: absolute;
  top: 16px;
  left: 16px;
  border-radius: 4px;
  z-index: 2;
}

.popup-close {
  cursor: pointer;
  width: 58px;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  transition: 0.5s;
  border: 1px solid transparent;
  position: absolute;
  top: 16px;
  right: 16px;

  &:hover {
    background: #e5e5e0;
    border: 1px solid #cecece;
  }
}

.popup-text-div {
  max-width: 360px;
  margin-top: 66px;
  margin-left: 36px;

  img {
    user-select: none;
    margin: 18px 0;
  }

  p {
    font-family: "jj";
    font-weight: 500;
    font-size: 18px;
    text-align: left;
    color: #000;
  }
}

.p-star-div {
  margin-top: 20px;
  display: flex;
  align-items: center;

  img {
    padding-right: 3px;
  }

  p {
    font-family: "jj";
    font-weight: 500;
    font-size: 12px;
    text-align: left;
    color: #000;
  }
}

.packks-buttons {
  display: flex;
  column-gap: 10px;
  margin-top: 64px;
}

.packks-button {
  margin: 0;
  font-family: "jj";
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #fff;
}

.copied-text {
  position: absolute;
  width: 60px;
  height: 60px;
  right: 25px;
  border-radius: 4px;

  img {
    width: 100%;
    height: 100%;
  }
}

.packs-title {
  margin-top: 52px;
  margin-bottom: 52px;
  text-align: center;
  h6 {
    font-family: "gr";
    font-size: 29px;
    text-align: center;
    color: #000;
  }

  p {
    font-family: "jj";
    font-weight: 900;
    font-size: 56px;
    color: #ea552b;
  }
}

@media (max-width: 575px) {
  .login-upper {
    height: 47px;
  }

  .login-back {
    padding: 0 10px;
    height: 36px;

    svg {
      width: 40px;
    }
  }

  .login-go {
    padding: 0 10px;
    height: 36px;
    padding-bottom: 3px;
  }

  .login-down {
    padding: 37px 14px 37px 14px;
    height: 403px;
  }

  .login-title {
    font-size: 24px;
  }

  .login-labels label p {
    font-size: 16px;
  }

  .login-labels label input {
    font-size: 16px;
    height: 46px;
    padding: 0 10px;
    padding-bottom: 2px;
  }

  .login-button {
    font-size: 16px;
    height: 46px;
  }

  .lg1 {
    position: absolute;
    top: -27px;
    right: -20px;
    width: 41px;
  }

  .lg2 {
    position: absolute;
    top: 72px;
    left: -15px;
    width: 44px;
  }

  .lg3 {
    position: absolute;
    top: 389px;
    right: -56px;
    width: 112px;
  }

  .lg4 {
    position: absolute;
    top: 58px;
    right: -16px;
    width: 44px;
  }

  .lg5 {
    position: absolute;
    top: 367px;
    left: -52px;
    width: 104px;
  }

  .lg6 {
    position: absolute;
    top: 41px;
    right: 13px;
    width: 20px;
  }

  .lg7 {
    position: absolute;
    top: 135px;
    right: 13px;
    width: 20px;
  }

  .login-button.signup {
    margin-top: 28px;
  }

  .login-text {
    line-height: 28px;
    font-size: 18px;
  }

  .scclg5 {
    position: absolute;
    top: 467px;
    left: -52px;
    width: 104px;
  }

  .login-down.sccdiv {
    height: auto;
  }

  .packs-title {
    transform: scale(0.9);
    margin-top: 62px;
    margin-bottom: 32px;
  }

  .iban-input {
    font-size: 15px;
  }

  .iban-button {
    transition: all 0.14s ease-out 0s;

    &:hover {
      transform: translate(0, 0);
      box-shadow: 0 0 0 #ea552b;
    }
  }

  .packks-buttons {
    margin-top: 44px;
  }

  .packks-button {
    font-size: 15px;
    height: 60px;
  }

  .login-down.ppacks {
    height: 538px;
    padding: 0 22px;
  }

  .popup-text-div img {
    height: 42px;
  }

  .popup-text-div p {
    font-size: 16px;
    line-height: 22px;
  }

  .popup-text-div {
    margin-top: 66px;
    margin-left: 22px;
    margin-right: 22px;
  }

  .p-star-div img {
    height: 13px;
  }

  .p-star-div p {
    font-size: 12px;
  }

  .popup-content {
    height: 538px;
  }

  .login-down.termss {
    height: 420px;
  }

  .lg5.termss {
    position: absolute;
    top: 457px;
    left: -37px;
    width: 74px;
  }
}

@media (max-width: 390px) {
  .login-text {
    font-size: 17px;
  }

  .login-down.termss {
    height: auto;
  }
}

@media (max-width: 373px) {
  .login-text {
    font-size: 16px;
    line-height: 26px;
  }
  .lg5.termss {
    display: none;
  }
  .login-down.termss {
    padding-bottom: 14px;
  }
}
