/** @format */

body {
  font-family: "gr", sans-serif;
}

// #section7 {
//   background-color: rgb(255, 0, 76);
//   height: 500px;
// }

@import "./fonts.scss";
@import "./base.scss";
@import "./nav.scss";
@import "./section1.scss";
@import "./section2.scss";
@import "./section3.scss";
@import "./section4.scss";
@import "./section5.scss";
@import "./section6.scss";
@import "./section7.scss";
@import "./section8.scss";
@import "./section9.scss";
@import "./section10.scss";
@import "./section11.scss";
@import "./section12.scss";
@import "./text.scss";
@import "./login.scss";
@import "./error404.scss";
